import { call, fork, all } from 'redux-saga/effects'

import { authenticationSagas } from 'pkg/authentication'

import { websitePagesLayoutSagas } from 'components/WebsitePagesLayout'

import {
  watchDeleteDocById,
  watchMigrateDoc,
  watchGetWebsite,
} from './dashboard'

import { watchGetPageview } from './pageview'

import { watchRedirect, watchBuilderRedirect } from './redirect'

import {
  watchCookieReportInit,
  watchCreateCookie,
  watchUpdateCookie,
  watchUpdateCookieCategory,
  watchDeleteCookie,
  watchExportConsentLogs,
  watchUpdateCookiePolicy,
} from './cookieReport'

import { watchers as dashboardBusinessInfo } from './forms/dashboardBusinessInfo'
import { watchers as editWebsiteInfo } from './forms/editWebsiteInfo'
import { watchers as scanReport } from './panels/scanReport'
import { watchers as embedPreferenceCenterButton } from './forms/embedPreferenceCenterButton'
import { watchers as policy } from './panels/policy'
import { watchers as themeSaver } from './forms/themeSaver'
import { watchers as policyMigrator } from './forms/policyMigrator.js'
import { watchers as customerRequest } from './forms/customerRequest'

const runWatchers = (watchers) => Object.keys(watchers || {}).map((key) => watchers[key]())

export default function* rootSaga() {
  yield all([
    call(authenticationSagas),
    call(websitePagesLayoutSagas),
    fork(watchRedirect),
    fork(watchBuilderRedirect),
    fork(watchGetWebsite),
    fork(watchDeleteDocById),
    fork(watchMigrateDoc),
    fork(watchGetPageview),
    fork(watchCookieReportInit),
    fork(watchCreateCookie),
    fork(watchUpdateCookie),
    fork(watchUpdateCookieCategory),
    fork(watchDeleteCookie),
    fork(watchUpdateCookiePolicy),
    fork(watchExportConsentLogs),
    ...runWatchers(dashboardBusinessInfo),
    ...runWatchers(editWebsiteInfo),
    ...runWatchers(scanReport),
    ...runWatchers(embedPreferenceCenterButton),
    ...runWatchers(policy),
    ...runWatchers(themeSaver),
    ...runWatchers(policyMigrator),
    ...runWatchers(customerRequest),
  ])
}
