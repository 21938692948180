import useGetQuery from 'hooks/useGetQuery'

import getCacheKey from './getCacheKey'

const GET_URI = 'v1/users/info'


export default function useUserInfoQuery() {
  const {
    responseData,
    ...otherReturnValues
  } = useGetQuery({
    endpoint: GET_URI,
    queryKey: getCacheKey(),
  })

  const {
    excluded,
    ...user
  } = responseData

  return {
    ...otherReturnValues,
    excluded,
    user,
  }
}
